<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="goBack()">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>Genres</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <div class="app-max-width">
        <div v-if="genres != null">
          <!-- Genres -->
          <div v-if="genres.length > 0">
            <ion-chip v-for="genre in genres" :key="genre" @click.prevent="viewGenre(genre.slug)">
              <ion-label>{{genre.name}}</ion-label>
            </ion-chip>             
          </div>
        </div>
      </div>
    </ion-content>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonChip, IonLabel } from '@ionic/vue';
import { chevronBack, add, reorderThree, checkmark } from 'ionicons/icons';
import { defineComponent, ref, onMounted, computed } from 'vue';
import doAuth from '../../services/auth';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../../services/api';
//import PageGenres from '../../components/PageGenres.vue';

export default defineComponent({
  name: 'Genre',
  components: {
    IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonChip, IonLabel
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const genres = ref(null);

    onMounted(() => {
      getGenres()
    })

    function getGenres() {
      //presentLoading()
      apiClient.get('/api/get/genres', {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        genres.value = response.data
      }).catch(error => {
        console.log(error)  
      });          
    }

    function viewGenre(slug) {
      window.location = '/genre/'+slug
    }

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    return {
      chevronBack, add, reorderThree, checkmark, router, updateRoute, goBack, authUser, genres, viewGenre
    }
  },
  watch: {
    pageId: function() {
      if(this.pageId > 0) {
        this.getPage()
      }
    },
  }
});
</script>

<style scoped>

</style>